@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500&family=Roboto:wght@300;400;500;700&family=Source+Code+Pro&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #424242;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  margin-top: 2.5rem;
}
@media only screen and (max-width: 600px) {
  section {
    margin-top: 1rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #0099ff;
  text-decoration: none;
  transition: color 0.175s;
}

p > a:hover {
  color: #424242;
}

img {
  max-width: 100%;
  border-radius: 4px;
}
